import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { space, radius, colors, COLOR_CONSTANTS } from 'theme'
import { Box, Flex, Grid } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import ImageWithFallback from 'components/ImageWithFallback'
import Button from 'components/Button'
import Link from 'components/Link'
import Divider from 'components/Divider'
import badgeSmall from 'static/svg/badge_small_tool.svg'
import company from 'static/svg/company.svg'
import facebook from 'static/svg/facebook.svg'
import facebookDisabled from 'static/svg/facebook_disabled.svg'
import googlebusiness from 'static/svg/googlebusiness.svg'
import googlebusinessDisabled from 'static/svg/googlebusiness_disabled.svg'
import instagram from 'static/svg/instagram.svg'
import instagramDisabled from 'static/svg/instagram_disabled.svg'
import linkedin from 'static/svg/linkedin.svg'
import linkedinDisabled from 'static/svg/linkedin_disabled.svg'
import pinterest from 'static/svg/pinterest.svg'
import pinterestDisabled from 'static/svg/pinterest_disabled.svg'
import reddit from 'static/svg/reddit.svg'
import redditDisabled from 'static/svg/reddit_disabled.svg'
import twitter from 'static/svg/twitter.svg'
import twitterDisabled from 'static/svg/twitter_disabled.svg'
import vimeo from 'static/svg/vimeo.svg'
import vimeoDisabled from 'static/svg/vimeo_disabled.svg'
import youtube from 'static/svg/youtube.svg'
import youtubeDisabled from 'static/svg/youtube_disabled.svg'

const getSocialNetworkIconByName = () => {
  return {
    facebook: {
      enabled: facebook,
      disabled: facebookDisabled,
    },
    googlebusiness: {
      enabled: googlebusiness,
      disabled: googlebusinessDisabled,
    },
    instagram: {
      enabled: instagram,
      disabled: instagramDisabled,
    },
    linkedin: {
      enabled: linkedin,
      disabled: linkedinDisabled,
    },
    pinterest: {
      enabled: pinterest,
      disabled: pinterestDisabled,
    },
    reddit: {
      enabled: reddit,
      disabled: redditDisabled,
    },
    twitter: {
      enabled: twitter,
      disabled: twitterDisabled,
    },
    vimeo: {
      enabled: vimeo,
      disabled: vimeoDisabled,
    },
    youtube: {
      enabled: youtube,
      disabled: youtubeDisabled,
    },
  }
}

const StyledGrid = styled(Grid)`
  padding: ${space.l};
  background-color: ${colors.white};
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  box-shadow: 0px 2px 7px rgba(39, 40, 49, 0.05);
  transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
  padding: ${space.s};
  background-color: ${colors.white};
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const TwoHyphens = () => (
  <Flex my="s" maxWidth="40px" justifyContent="space-between">
    <Divider width="18px" bg={COLOR_CONSTANTS.GLACIER_PEARL} />
    <Divider width="18px" bg={COLOR_CONSTANTS.GLACIER_PEARL} />
  </Flex>
)

const Item = ({ picture_url, name, age, city, description, socialProfiles = [], slug, verified }) => {
  const href = `/hub/${slug}`
  const socialProfilesCount = socialProfiles.reduce((acc, { value }) => acc + value, 0)
  return (
    <StyledGrid gridTemplateColumns={{ mobile: '1fr', mobileLarge: '0.5fr 1.5fr repeat(1, 1.5fr) auto' }} gridGap="l">
      <Flex alignItems="flex-start" position="relative">
        {verified && (
          <Box position="absolute" left={{ mobile: '-50px', tablet: '-60px' }} top="-50px" zIndex={2}>
            <LocalImage source={badgeSmall} />
          </Box>
        )}
        <Link href={href}>
          <Box width={{ mobile: '70px', tablet: '100px' }} height={{ mobile: '70px', tablet: '100px' }}>
            {picture_url ? (
              <LogoWrapper>
                <ImageWithFallback
                  source={picture_url}
                  alt={`${name} | Agency Vista`}
                  objectFit="cover"
                  width={{ mobile: '50px', tablet: '70px' }}
                  fallbackSource={company}
                />
              </LogoWrapper>
            ) : (
              <LogoWrapper>
                <ImageWithFallback
                  source={picture_url}
                  alt={`${name} | Agency Vista`}
                  objectFit="cover"
                  width={{ mobile: '50px', tablet: '70px' }}
                  fallbackSource={company}
                />
              </LogoWrapper>
            )}
          </Box>
        </Link>
      </Flex>
      <Flex flexDirection="column">
        <Flex alignItems="center">
          <Link href={href} mr="s" fontSize="l" fontWeight="bold" textAlign="left">
            {name}
          </Link>
        </Flex>
        <Text my="s" color="secondaryText" textAlign="left">
          {age && city ? `${age} · ${city}` : age || city}
        </Text>
        <Text color="secondaryText" textAlign="left">
          {description}
        </Text>
      </Flex>
      <Flex flexDirection="column">
        {socialProfilesCount ? (
          <Text fontSize="m" textAlign="left">
            {socialProfilesCount}
          </Text>
        ) : (
          <TwoHyphens />
        )}
        <Text my="s" color="secondaryText" textAlign="left">
          {`${socialProfilesCount === 1 ? 'Social Profile' : 'Social Profiles'}`}
        </Text>
        {!!socialProfilesCount && (
          <Flex flexWrap="wrap">
            {socialProfiles.map(({ code, value }) => {
              const isEnabled = value !== 0
              return (
                <Flex key={code} mr="s" flexDirection="column" alignItems="center">
                  <LocalImage
                    source={getSocialNetworkIconByName()[code.toLowerCase()][isEnabled ? 'enabled' : 'disabled']}
                    width="20px"
                  />
                  <Text mt="s" fontSize="xxs" color="secondaryText">
                    {value}
                  </Text>
                </Flex>
              )
            })}
          </Flex>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end">
        <Button.Gray as={Link} href={href} target="_blank" isSmall>
          <Text color="ternaryText" fontWeight="medium">
            View Profile
          </Text>
        </Button.Gray>
      </Flex>
    </StyledGrid>
  )
}

Item.defaultProps = {
  picture_url: '',
  city: '',
  description: '',
  verified: null,
  age: '',
  socialProfiles: [],
}

Item.propTypes = {
  picture_url: PropTypes.string,
  name: PropTypes.string.isRequired,
  age: PropTypes.string,
  city: PropTypes.string,
  description: PropTypes.string,
  socialProfiles: PropTypes.array,
  slug: PropTypes.string.isRequired,
  verified: PropTypes.number,
}

export default memo(Item)
