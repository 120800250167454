/* eslint-disable camelcase */
import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, radius, colors, COLOR_CONSTANTS } from 'theme'
import { formatToNumberWithCommas } from 'helpers'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, H4, Text } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import ImageWithFallback from 'components/ImageWithFallback'
import Input from 'components/Input'
import Footer from 'components/Footer'
import Link from 'components/Link'
import search from 'static/svg/search.svg'
import badgeSmall from 'static/svg/badge_small_tool.svg'
import Item from './Item'

const StyledGrid = styled(Grid)`
  padding: ${space.s} 20px ${space.s} ${space.s};
  background-color: ${colors.white};
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  box-shadow: 0px 2px 7px rgba(39, 40, 49, 0.05);
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 90px;
  max-width: 150px;
  position: relative;
  background-color: ${colors.white};
`
const ImgWrapper = styled(ImageWithFallback)`
  max-width: 100%;
  max-height: 100%;
  min-width: 70px;
`

const StyledText = styled(Text)`
  color: ${COLOR_CONSTANTS.VENUS_MIST};
`

const StyledImage = styled(LocalImage)`
  cursor: pointer;
`

const Tools = ({ pageContext: { featuredTools, notFeaturedTools } }) => {
  const toolsWithAgencies = notFeaturedTools.filter(({ used }) => used > 0)
  const [toolsOnPage, setToolsOnPage] = useState([...toolsWithAgencies])
  const [featuredToolsOnPage, setFeaturedToolsOnPage] = useState([...featuredTools])

  const [searchValue, setSearchValue] = useState('')
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value)
  }
  const handleSubmitSearch = (e) => {
    e.preventDefault()
    if (searchValue) {
      setToolsOnPage(toolsWithAgencies.filter(({ name }) => name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1))
      setFeaturedToolsOnPage(
        featuredTools.filter(({ name }) => name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
      )
    } else {
      setToolsOnPage([...toolsWithAgencies])
      setFeaturedToolsOnPage([...featuredTools])
    }
  }
  return (
    <App>
      <SEO
        title="Agency Vista Hub"
        description="See which social media management tools marketing agencies around the world are using."
        path="/hub/"
      />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H1 textAlign="center">Agency Vista Hub</H1>
          <Text mt="m" color="secondaryText" opacity={0.8} fontSize="l" textAlign="center">
            Find Marketing Tools that Agencies and Businesses Love
          </Text>

          <Box my="l">
            <form onSubmit={handleSubmitSearch}>
              <Input
                onChange={handleSearchChange}
                width="300px"
                placeholder="Search by tool"
                pl="40px"
                iconPosition={{ left: '10px' }}
                iconComp={<StyledImage src={search} onClick={handleSubmitSearch} />}
                hasBoxShadow
                borderRadius={radius.m}
              />
            </form>
          </Box>

          {featuredToolsOnPage.length > 0 && (
            <Fragment>
              <Box width="100%" mt="m" px="s">
                <Text color="secondaryText" opacity={0.8} fontSize="l" textAlign="left">
                  Top Resources For Agencies
                </Text>
              </Box>
              <Box mt="l" width="100%" px="s">
                {featuredToolsOnPage.map((item) => (
                  <Box key={item.id} my="m">
                    <Item {...item} />
                  </Box>
                ))}
              </Box>
            </Fragment>
          )}

          {toolsOnPage.length > 0 && (
            <Fragment>
              <Box width="100%" my="l" px="s">
                <Text color="secondaryText" opacity={0.8} fontSize="l" textAlign="left">
                  Marketing and CRM Software
                </Text>
              </Box>

              <Grid
                py="m"
                px="s"
                width={['auto', 'auto', '900px']}
                gridTemplateColumns={{ mobile: '1fr', mobileLarge: '1fr 1fr', tablet: 'repeat(3, 1fr)' }}
                gridGap="m"
              >
                {toolsOnPage.map(({ id, name, used, picture_url, slug, verified }, key) => {
                  return (
                    <StyledGrid
                      key={id}
                      gridTemplateColumns={['1fr 1fr', 'repeat(2, 1fr)']}
                      gridGap="s"
                      as={Link}
                      href={`/hub/${slug}`}
                      title={name}
                    >
                      <Flex alignItems="center" position="relative" justifyContent="flex-start">
                        {verified && (
                          <Box position="absolute" left="-35px" top="-50px" zIndex={2}>
                            <LocalImage source={badgeSmall} />
                          </Box>
                        )}
                        <LogoWrapper>
                          {picture_url ? <ImgWrapper source={picture_url} alt={name} /> : <H4>{name}</H4>}
                        </LogoWrapper>
                      </Flex>
                      <Flex alignItems="center" justifyContent="flex-end">
                        <StyledText fontSize="m" color="secondaryText">
                          {`${formatToNumberWithCommas(used)} ${used === 1 ? 'agency' : 'agencies'}`}
                        </StyledText>
                      </Flex>
                    </StyledGrid>
                  )
                })}
              </Grid>
            </Fragment>
          )}
        </Flex>
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

Tools.defaultProps = {
  pageContext: {
    notFeaturedTools: [],
    featuredTools: [],
  },
}

Tools.propTypes = {
  pageContext: PropTypes.shape({
    notFeaturedTools: PropTypes.array,
    featuredTools: PropTypes.array,
  }),
}

export default Tools
